<template>
    <Project v-if="project.id" :project="project" />
</template>

<script>
import { PROJECT_DATA_URL } from '@/assets.js';
import Project from "@/components/Project.vue";
import BossRemote from "@/intern.js";

function fetchProjects(to, next) {
    fetch(PROJECT_DATA_URL)
        .then(resp => resp.json())
        .then(data => {
            next(vm => {
                vm.projects = data;
                vm.project = data[vm.projectId] ?? data[vm.projectId.toLowerCase()]; // TODO: better solution to case mis-match?
            });
        });
}

export default {
    components: {
        Project,
    },

    props: {
        projectId: {
            type: String,
        },
    },

    data() {
        return {
            project: {},
            projects: null,
        };
    },

    beforeRouteEnter(to, from, next) {
        fetchProjects(to, next);
    },

    beforeRouteUpdate(to, from, next) {
        this.project = this.projects[to.params.projectId];
        next();
    },

    created() {
        let bossRemote = new BossRemote();
        // bossRemote.getCollectionMetadata(this.projectId).then((res) => {
        //     this.project = res;
        //     this.project.hero = (ASSETS_BASE_URL + "projects/" + res.hero);
        //     this.project.media = (ASSETS_BASE_URL + "projects/" + res.media);
        // }).catch((err) => {
        //     // If the API returns an error, fall back on static file data
        //     console.error(err);
        //     this.project = projectData;
        // });
    },
};
</script>
