import _ from "lodash";

/**
 * Is the string or array effectively empty?
 * @param strOrArray A string or an array of strings.
 * @returns true if not effectively empty.
 */
const nonEmpty = (strOrArray) => _.filter(strOrArray, item => {
  if (typeof item === 'string') {
    return item.trim().length > 0;
  }
  if (Array.isArray(strOrArray)) {
    return strOrArray.some(item => {
      if (typeof item === 'string') {
        return item.trim().length > 0;
      }
      console.error(`nonEmpty passed an array with a non-string element: ${typeof item}`);
      return false;
    });
  }

  console.error(`nonEmpty not called with string or array: ${typeof strOrArray}`);
  return false;
});

/**
 * If an element of `arr` is itself an array, then compare the first element of
 * that array against the other element.
 * @param arr Array[string | string[]]
 */
const sortedIgnoreCase = (arr) => arr.sort((a, b) => {
  let a_lower;
  if (Array.isArray(a)) {
    a_lower = a[0].toLowerCase();
  } else {
    a_lower = a.toLowerCase();
  }

  let b_lower;
  if (Array.isArray(b)) {
    b_lower = b[0].toLowerCase();
  } else {
    b_lower = b.toLowerCase();
  }

  if (a_lower < b_lower) {
    return -1;
  } else if (a_lower > b_lower) {
    return 1;
  } else {
    return 0;
  }
});

const attributeValuesFromPublicProjects = (projects, attribute) => {
  return _.filter(projects, "public")
          .map((p) => p[attribute]);
}

export const projectMatchesFacet = (project, facet) => {
  const noSelectionForFacet = (facet.requiredValue.length === 0);   // Whether the facet type is string or Array, if nothing's selected it has length 0.

  return noSelectionForFacet ||
         (facet.equalIf ?
            facet.equalIf(project[facet.attribute], facet.requiredValue) :
            project[facet.attribute] === facet.requiredValue
         )
}

const attributeValues = ({projects, attribute, flatten}) => {
  const rawValues = attributeValuesFromPublicProjects(projects, attribute);
  const flattenedValues = flatten ? _.flatten(rawValues) : rawValues;

  const cleanedSortedValues = sortedIgnoreCase(
    _.uniq(
      nonEmpty(flattenedValues)
    )
  );

  return cleanedSortedValues;
}

export const attributeValuesBy = ({projects, attribute, facets, searchText, searchTextMatchCondition}, flatten=false) => {
  const projectsByFacetedSearch = (p) => searchTextMatchCondition(p, searchText) &&
                                         facets.every(r => projectMatchesFacet(p, r));

  return attributeValues(
    {
      projects:_.filter(projects, projectsByFacetedSearch),
      attribute:attribute,
      flatten:flatten
    }
  );
}

export default attributeValues;
