<template>
    <Tag :tag="tag" v-bind:allProjects="allProjects" />
</template>

<script>
import { PROJECT_DATA_URL } from '@/assets.js';
import Tag from "@/components/Tag.vue";

function fetchProjects(to, next) {
    fetch(PROJECT_DATA_URL)
        .then(resp => resp.json())
        .then(data => {
            next(vm => {
                vm.allProjects = data;
            });
        });
}

export default {
    components: {
        Tag
    },

    props: {
        tag: {
            type: String
        }
    },

    beforeRouteEnter(to, from, next) {
        fetchProjects(to, next);
    },

    beforeRouteUpdate(to, from, next) {
        next();
    },

    data() {
        return {
            allProjects: {},
        };
    },
};
</script>
